
<script>
    import Advertising from '@/services/Advertising'
    import Team from '@/services/Teams'
    import Swal from "sweetalert2";

    export default {
        props: {
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                tryingToSubmit: false,
                competition_id: process.env.VUE_APP_COMPETITION_ID,
                teams: [],
                team_id: null,
                players: [],
                player_id: null,
                sponsors: [],
                sponsor_id: null,
                media: [],
                media_id: null,
                link_url: '',
                media_url: null,
                media_type: null,
                media_width: null,
                media_height: null,
                media_text: null,
                media_active: null,
            };
        },

        methods: {

            closeModal() {
                this.showModal = false
                this.team_id = null
                this.player_id = null
                this.sponsor_id = null
                this.media_id = null
                this.teams = []
                this.media = []
                this.link_url= ''
                this.media_url= null
                this.media_type= null
                this.media_width= null
                this.media_height= null
                this.media_text= null
                this.media_active= null
            },

            refreshTable() { 
                this.$emit('refreshTable') //event from parent
            },

            getTeams() {
                this.teams =  [];
                this.players = [],
                this.sponsors =  [];
                this.media =  [];

                Team.getTeamsByCompetition(this.competition_id)
                .then((response) => {
                    //console.log(response)
                    this.teams =  response.data.competitions[0].teams;
                })
            },

            getPlayers(team_id) {
                this.players =  [];

                Team.getTeamSquad(team_id)
                .then((response) => {
                    //console.log(response)
                    this.players =  response.data.squad[0].players;
                })
            },

            getSponsors(){
                this.sponsors =  [];
                this.media =  [];

                Advertising.getSponsors()
                .then((response) => {
                    this.sponsors =  response.data.data;
                })
            },

            getSponsorMedia(sponsor_id) {
                this.media =  [];
                Advertising.getSponsorById(sponsor_id)
                .then((response) => {
                    //console.log(response)
                    this.link_url = response.data.data[0].url
                    this.media =  response.data.data[0].media
                })
            },

            selectMedia(event){
                var mediaSelected = this.media.find(item => item.id == event.target.value)
                //console.log(mediaSelected)
                this.media_url = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.source_url : null
                this.media_type = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.type : null
                this.media_text = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.text : null
                this.media_width = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.width : null
                this.media_height = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.height : null
                this.media_active = typeof(mediaSelected) != 'undefined' && mediaSelected != null  ? mediaSelected.active : null
            },

            initialData(){
                this.getTeams();
                this.getSponsors()
            },

            addSPonsoredPlayer(){
                this.tryingToSubmit = true;
                Advertising.attachSponsoredPlayer({
                    player_id: this.player_id,
                    sponsor_id: this.sponsor_id,
                    media_id: this.media_id,
                    link_url: this.link_url,
                })
                .then((response) => {
                    this.tryingToSubmit = false;
                    const res = response.data ? response.data : false;
                    const error = response.data.error ? response.data.error : 'Failed';
                    if(res){
                        this.refreshTable();
                        Swal.fire("Success!", "Sponsor Added to Player", "success");
                        this.closeModal();
                    }else{
                        Swal.fire("Fail!", error, "warning");
                    }
                })
                .catch(error => {
                    this.tryingToSubmit = false;
                    Swal.fire("Fail!", error, "warning");
                });
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },


        }
    }
</script>


<template>
    <b-modal
        @shown="initialData"
        id="add_sponsored_player"
        v-model="showModal"
        title="Add New Sponsored Player"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
        centered
    >
    <form @submit.prevent="addSPonsoredPlayer">
        <div class="row">
          <div class="col-12">

            <div class="mb-3">
              <label class="control-label form-label">Choose Team</label>
              <select v-model="team_id" class="form-control form-select" name="team_id" type="select" @change="getPlayers(team_id)">
                <b-form-select-option :value="null">Please select Team</b-form-select-option>
                <option v-for="team in teams" :key="team.id" :value="team.team_short">
                    {{ team.team_name }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="control-label form-label">Choose Player</label>
              <select v-model="player_id" class="form-control form-select" name="player_id" type="select">
                <b-form-select-option :value="null">Please select Player</b-form-select-option>
                <option v-for="player in players" :key="player.id" :value="player.id">
                    {{ player.first_name }} - {{ player.last_name }} - ( {{ player.function }} )
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="control-label form-label">Choose Sponsor</label>
              <select v-model="sponsor_id" class="form-control form-select" name="sponsor_id" type="select" @change="getSponsorMedia(sponsor_id)">
                <b-form-select-option :value="null">Please select Sponsor</b-form-select-option>
                <option v-for="sponsor in sponsors" :key="sponsor.id" :value="sponsor.id">
                    {{ sponsor.name }} 
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="control-label form-label">Choose Media</label>
              <select v-model="media_id" class="form-control form-select" name="sponsor_id" type="select" @change="selectMedia">
                <b-form-select-option :value="null">Please select Media</b-form-select-option>
                <option v-for="item in media" :key="item.id" :value="item.id">
                    {{item.id  + ' | ' + item.text }} 
                </option>
              </select>
            </div>

            <div class="mb-3">
                <img v-if="media_url" :src="media_url"  width="200"   style="max-height: 150px;" class="avatar-lg" />
                <img v-else  width="200" src="@/assets/images/image_not_found.png"  style="max-height: 150px;" class=" avatar-lg"  />
                <p class="mb-1">Media Type: {{media_type}}</p>
                <p class="mb-1">Media Text: {{media_text}}</p>
                <p class="mb-1">Media Width: {{media_width}}</p>
                <p class="mb-1">Media Height: {{media_height}}</p>
                <p class="mb-1">Media Active: {{media_active}}</p>
            </div>

            <div class="mb-3">
                <label class="control-label form-label label-for">Link URL</label>
                <b-form-input  v-model="link_url"></b-form-input>
            </div>
            

          </div>
        </div>

        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button 
                type="submit" 
                variant="primary" 
                class="ms-3" 
                :disabled="team_id==null || player_id==null || sponsor_id==null || media_id==null || tryingToSubmit"
                >
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>