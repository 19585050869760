
import Http from './Http'

export default {
    async getSponsors() {
        return await Http.get(`/advertising/sponsors`)
    },
    async getRssAdvertorial() {
        return await Http.get(`advertising/rss_advertorial`)
    },
    async editRssAdvertorial(data) {
        return await Http.post(`advertising/rss_advertorial`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getSponsorById(sponsor_id) {
        return await Http.get(`/advertising/sponsors?id=${sponsor_id}`)
    },
    async createSponsorAttributes(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}/attribute`, data)
    },
    async removeAttribute(sponsor_id, attribute) {
        return await Http.delete(`/advertising/sponsors/${sponsor_id}/attribute/${attribute}`)
    },
    async addSponsor(data) {
        return await Http.post(`/advertising/sponsors`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async editSponsor(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getSponsoredPlayers() {
        return await Http.get(`/advertising/sponsors/player`)
    },
    async attachSponsoredPlayer(data) {
        return await Http.patch(`/advertising/sponsors/player/attach`, data)
    },
    async detachSponsoredPlayer(data) {
        return await Http.patch(`/advertising/sponsors/player/detach`, data)
    },
    async addSponsoredMedia(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}/media`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async removeSponsoredMedia(sponsor_id, media_id) {
        return await Http.delete(`/advertising/sponsors/${sponsor_id}/media/${media_id}`)
    },
    async getSponsorWall() {
        return await Http.get(`/advertising/sponsors/wall`)
    },
    async detachSponsorWall(data) {
        return await Http.patch(`/advertising/sponsors/wall/detach`, data)
    },
    async attachSponsorWall(data) {
        return await Http.patch(`/advertising/sponsors/wall/attach`, data)
    },
}


